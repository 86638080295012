export enum EXPERIMENT_ID {
  DYNAMIC_QUESTION_EXPERIMENT_ID = '0021_dynamic_question',
  DYNAMIC_QUESTION_EXPERIMENT_ID_V2 = '0023_dynamic_question_V2',
  INDEX_MOD_EXPERIMENT_ID = '0024_index_mod',
}

export const dynamicQuestionExperimentIDs: string[] = [
  EXPERIMENT_ID.DYNAMIC_QUESTION_EXPERIMENT_ID,
  EXPERIMENT_ID.DYNAMIC_QUESTION_EXPERIMENT_ID_V2,
];
